html,
body {
  font-family:
    "Napa",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  color: var(--color-gray-1);
  margin: 0;
  min-height: 100dvh;
  min-height: -webkit-fill-available;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--color-primary);
}

img,
video {
  max-width: 100%;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

input,
textarea {
  font-family:
    "Napa",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #fff;
}

iframe {
  max-width: 100%;
}

@font-face {
  font-family: "Napa";
  src:
    local("Napa"),
    url("../public/fonts/GT-America-Napa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Napa";
  src:
    local("Napa"),
    url("../public/fonts/GT-America-Napa-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Napa";
  src:
    local("Napa"),
    url("../public/fonts/GT-America-Napa-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Rift";
  src: url("../public/fonts/Rift-Demi.otf");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Rift";
  src: url("../public/fonts/Rift-Bold.woff") format("woff"), local("Rift");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Rift";
  src: url("../public/fonts/Rift-Demi.otf");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "SpaceMono";
  src:
    local("Space Mono"),
    local("Space Mono Regular"),
    url("../public/fonts/SpaceMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

::selection {
  background: var(--color-primary);
  color: var(--color-gray-7);
}

/* Variables */
:root {
  --color-white: #ffffff;
  --color-deep-purple: #313147;
  --color-milk: #fbfbff;

  /* Theme abstractions. */
  --light-mode-gray-1: #313131;
  --light-mode-gray-2: #4f4f4f;
  --light-mode-gray-3: #828282;
  --light-mode-gray-4: #bdbdbd;
  --light-mode-gray-5: #e0e0e0;
  --light-mode-gray-6: #f2f2f2;
  --light-mode-gray-7: #fafafa;

  --light-mode-pink-base: #0000ff;
  --light-mode-pink-1: #ffe7f4;
  --light-mode-pink-2: #ffcce9;
  --light-mode-pink-3: #ffb1dc;
  --light-mode-pink-4: #ff80c9;
  --light-mode-pink-5: #ff4db3;
  --light-mode-pink-6: #0000ff;
  --light-mode-pink-7: #cc0075;
  --light-mode-pink-8: #990058;
  --light-mode-pink-9: #66003a;
  --light-mode-pink-10: #33001d;

  --light-mode-background: #ffffff;
  --light-mode-home-background: #fefbff;
  --light-mode-charcoal: #1d2b3a;
  --light-mode-error: #ed0000;
  --light-mode-success: #1cdfb0;
  --light-mode-completed: #16b900;
  --light-mode-incomplete: #ffb802;
  --light-mode-warning: #ff7300;

  --light-mode-category-bunches-secondary: var(--light-mode-pink-base);
  --light-mode-category-business-secondary: #00958c;
  --light-mode-category-food-secondary: #8cde00;
  --light-mode-category-fun-secondary: #ff7300;
  --light-mode-category-gaming-secondary: #7f24ff;
  --light-mode-category-lifestyle-secondary: #6363ce;
  --light-mode-category-music-secondary: #49cbff;
  --light-mode-category-sports-secondary: #00e0e0;
  --light-mode-category-style-secondary: #ffd900;
  --light-mode-category-tech-secondary: #0086c4;
  --light-mode-category-travel-secondary: #165bf2;
  --light-mode-category-other-secondary: #b7b7b7;

  --light-mode-category-bunches-primary: #fff3f9;
  --light-mode-category-business-primary: #e4f2f1;
  --light-mode-category-food-primary: #eff7e1;
  --light-mode-category-fun-primary: #ffeee0;
  --light-mode-category-gaming-primary: #f3ebff;
  --light-mode-category-lifestyle-primary: #ededff;
  --light-mode-category-music-primary: #e5f8ff;
  --light-mode-category-sports-primary: #e8fafa;
  --light-mode-category-style-primary: #fefbe7;
  --light-mode-category-tech-primary: #e3f3fa;
  --light-mode-category-travel-primary: #e4edff;
  --light-mode-category-other-primary: #f6f6f6;

  --dark-mode-gray-1: #fafafa;
  --dark-mode-gray-2: #f2f2f2;
  --dark-mode-gray-3: #e0e0e0;
  --dark-mode-gray-4: #bdbdbd;
  --dark-mode-gray-5: #828282;
  --dark-mode-gray-6: #4f4f4f;
  --dark-mode-gray-7: #313131;

  --dark-mode-pink-base: #ff0088;
  --dark-mode-pink-1: #33001d;
  --dark-mode-pink-2: #66003a;
  --dark-mode-pink-3: #990058;
  --dark-mode-pink-4: #cc0075;
  --dark-mode-pink-5: #ff0088;
  --dark-mode-pink-6: #ff4db3;
  --dark-mode-pink-7: #ff80c9;
  --dark-mode-pink-8: #ffb1dc;
  --dark-mode-pink-9: #ffcce9;
  --dark-mode-pink-10: #ffe7f4;

  --dark-mode-background: #1e1e1e;
  --dark-mode-home-background: #1e1e1e;
  --dark-mode-charcoal: #1d2b3a;
  --dark-mode-error: #ed0000;
  --dark-mode-success: #1cdfb0;
  --dark-mode-warning: #ff7300;

  --dark-mode-category-bunches-secondary: var(--dark-mode-pink-base);
  --dark-mode-category-business-secondary: #08a196;
  --dark-mode-category-food-secondary: #82c410;
  --dark-mode-category-fun-secondary: #e8700e;
  --dark-mode-category-gaming-secondary: #752cdb;
  --dark-mode-category-lifestyle-secondary: #6868c4;
  --dark-mode-category-music-secondary: #3ebdf0;
  --dark-mode-category-sports-secondary: #18c4c4;
  --dark-mode-category-style-secondary: #dab900;
  --dark-mode-category-tech-secondary: #097bb0;
  --dark-mode-category-travel-secondary: #225bd6;
  --dark-mode-category-other-secondary: #999999;

  --dark-mode-category-bunches-primary: #fff3f9;
  --dark-mode-category-business-primary: #e4f2f1;
  --dark-mode-category-food-primary: #eff7e1;
  --dark-mode-category-fun-primary: #ffeee0;
  --dark-mode-category-gaming-primary: #f3ebff;
  --dark-mode-category-lifestyle-primary: #ededff;
  --dark-mode-category-music-primary: #e5f8ff;
  --dark-mode-category-sports-primary: #e8fafa;
  --dark-mode-category-style-primary: #fefbe7;
  --dark-mode-category-tech-primary: #e3f3fa;
  --dark-mode-category-travel-primary: #e4edff;
  --dark-mode-category-other-primary: #f6f6f6;

  /* Use these in code. */
  --color-gray-1: var(--light-mode-gray-1);
  --color-gray-2: var(--light-mode-gray-2);
  --color-gray-3: var(--light-mode-gray-3);
  --color-gray-4: var(--light-mode-gray-4);
  --color-gray-5: var(--light-mode-gray-5);
  --color-gray-6: var(--light-mode-gray-6);
  --color-gray-7: var(--light-mode-gray-7);

  --color-primary: var(--light-mode-pink-base);
  --color-primary-hover: var(--light-mode-pink-5);
  --color-pink-base: var(--light-mode-pink-base);
  --color-pink-1: var(--light-mode-pink-1);
  --color-pink-2: var(--light-mode-pink-2);
  --color-pink-3: var(--light-mode-pink-3);
  --color-pink-4: var(--light-mode-pink-4);
  --color-pink-5: var(--light-mode-pink-5);
  --color-pink-6: var(--light-mode-pink-6);
  --color-pink-7: var(--light-mode-pink-7);
  --color-pink-8: var(--light-mode-pink-8);
  --color-pink-9: var(--light-mode-pink-9);
  --color-pink-10: var(--light-mode-pink-10);

  --color-background: var(--light-mode-background);
  --color-home-background: var(--light-mode-home-background);
  --color-charcoal: var(--light-mode-charcoal);
  --color-error: var(--light-mode-error);
  --color-purple: #6172f3;
  --color-purple-hover: #8394ff;
  --color-success: var(--light-mode-success);
  --color-completed: var(--light-mode-completed);
  --color-incomplete: var(--light-mode-incomplete);
  --color-warning: var(--light-mode-warning);

  --color-category-bunches-secondary: var(--light-mode-category-bunches-secondary);
  --color-category-business-secondary: var(--light-mode-category-business-secondary);
  --color-category-food-secondary: var(--light-mode-category-food-secondary);
  --color-category-fun-secondary: var(--light-mode-category-fun-secondary);
  --color-category-gaming-secondary: var(--light-mode-category-gaming-secondary);
  --color-category-lifestyle-secondary: var(--light-mode-category-lifestyle-secondary);
  --color-category-music-secondary: var(--light-mode-category-music-secondary);
  --color-category-sports-secondary: var(--light-mode-category-sports-secondary);
  --color-category-style-secondary: var(--light-mode-category-style-secondary);
  --color-category-tech-secondary: var(--light-mode-category-tech-secondary);
  --color-category-travel-secondary: var(--light-mode-category-travel-secondary);
  --color-category-other-secondary: var(--light-mode-category-other-secondary);

  --color-category-bunches-primary: var(--light-mode-category-bunches-primary);
  --color-category-business-primary: var(--light-mode-category-business-primary);
  --color-category-food-primary: var(--light-mode-category-food-primary);
  --color-category-fun-primary: var(--light-mode-category-fun-primary);
  --color-category-gaming-primary: var(--light-mode-category-gaming-primary);
  --color-category-lifestyle-primary: var(--light-mode-category-lifestyle-primary);
  --color-category-music-primary: var(--light-mode-category-music-primary);
  --color-category-sports-primary: var(--light-mode-category-sports-primary);
  --color-category-style-primary: var(--light-mode-category-style-primary);
  --color-category-tech-primary: var(--light-mode-category-tech-primary);
  --color-category-travel-primary: var(--light-mode-category-travel-primary);
  --color-category-other-primary: var(--light-mode-category-other-primary);

  --font-family: "Napa", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;

  --font-family-headline: "Rift", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  --font-family-mono: "SpaceMono", -apple-system, mono;

  /* Animation & Timing */
  --animation-timing-veryQuick: 100ms;
  --animation-timing-quick: 200ms;
  --animation-timing-slow: 400ms;

  --timing-easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);
  --timing-easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);

  /* Shadows */

  --shadow-sm: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  --shadow-md: rgba(0, 0, 0, 0.1) 0px 6px 20px 0px;
  --shadow-lg: rgba(0, 0, 0, 0.1) 0px 10px 36px 0px;
  --shadow-mega: 0px 72px 48px rgba(0, 0, 0, 0.2);
  --shadow-angled: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05),
    -2px 5px 5px 0px rgba(0, 0, 0, 0.04), -4px 10px 6px 0px rgba(0, 0, 0, 0.03), -6px 18px 8px 0px rgba(0, 0, 0, 0.01),
    -10px 28px 8px 0px rgba(0, 0, 0, 0);
  --shadow-hard: 12px 12px 0px 0px #313131;

  /* Z-Index */

  --z-base: 0;
  --z-above: 5;
  --z-below: -5;

  --z-layer-1: 0;
  --z-layer-2: 1;
  --z-layer-3: 2;

  --z-layer-flyout: 50;
  --z-layer-dropdown: 60;

  --z-layer-modal: 100;
  --z-layer-toast: 110;
  --z-layer-contextMenu: 120;
  --z-layer-loader: 130;

  --z-chat: var(--z-layer-1);
  --z-chat-actions: var(--z-chat);
  --z-chat-loader: calc(var(--z-above) + var(--z-chat-actions));
  --z-chat-fileUpload: calc(var(--z-above) + var(--z-chat-loader));

  --z-leftSidebar: var(--z-layer-3);
  --z-leftSidebar-image: calc(var(--z-above) + var(--z-leftSidebar));
  --z-leftSidebar-indicator: calc(var(--z-above) + var(--z-leftSidebar-image));

  --z-rightSidebar: var(--z-layer-3);
  --z-rightSidebar-loader: calc(var(--z-above) + var(--z-rightSidebar));
  --z-rightSidebar-fileUpload: calc(var(--z-above) + var(--z-rightSidebar));

  --z-header: var(--z-layer-dropdown);

  --z-emoji-picker: var(--z-layer-modal);

  --z-modal-overlay: var(--z-layer-modal);
  --z-modal-content: calc(var(--z-above) + var(--z-layer-modal));
}

/* Keyframe Animations*/

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes twist {
  0% {
    transform: rotate(0);
    scale: 1;
  }
  30% {
    transform: rotate(30deg);
    scale: 1.07;
  }
  45% {
    transform: rotate(20deg);
    scale: 1.05;
  }
  65% {
    transform: rotate(30deg);
    scale: 1.07;
  }
  80% {
    transform: rotate(20deg);
    scale: 1.05;
  }
  100% {
    transform: rotate(0);
    scale: 1;
  }
}

.twist {
  animation: none;

  @media (min-width: 900px) {
    animation: twist 700ms var(--timing-easeOutQuart) 1.5s;
  }
}

@keyframes horizontal-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: horizontal-shake 300ms var(--timing-easeOutQuart);
}

@keyframes slide-top-fwd {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-top-bck {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-200%);
    opacity: 0;
  }
}

.slide-top-fwd {
  animation: slide-top-fwd 400ms var(--timing-easeOutQuart) both;
}

.slide-top-bck {
  animation: slide-top-bck 400ms var(--timing-easeInOutQuart) both;
}

@keyframes slide-bottom-up {
  0% {
    transform: translate(-50%, 200%);
    opacity: 0;
    display: none;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slide-bottom-down {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 200%);
    opacity: 0;
    display: none;
  }
}

.slide-bottom-up {
  animation: slide-bottom-up 400ms var(--timing-easeOutQuart) both;
}

.slide-bottom-down {
  animation: slide-bottom-down 400ms var(--timing-easeOutQuart) both;
}

@keyframes grow {
  0% {
    max-height: 10px;
    overflow: hidden;
  }
  100% {
    max-height: 600px;
    overflow: hidden;
  }
}

.grow {
  animation: grow 600ms var(--timing-easeOutQuart) both;
}

@keyframes expand {
  0% {
    max-width: 1rem;
  }
  100% {
    max-width: 100%;
  }
}

.expand {
  animation: expand 1500ms var(--timing-easeInOutQuart) both;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes progress {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
  }
  100% {
    width: 100%;
    left: 100%;
  }
}

.draw-svg {
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  animation: dash 200ms linear forwards;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

@keyframes dismiss {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%);
    display: none;
  }
}

.dismiss {
  animation: dismiss 500ms var(--timing-easeOutQuart) both;
}

.scroll-container {
  position: fixed;
  will-change: transform;
  right: 0;
  left: 0;
}
